<template>
  <div>
    <v-card>
      <v-card-title>Caja diaria</v-card-title>
      <DailyPaymentsList />
    </v-card>
  </div>
</template>

<script>
import DailyPaymentsList from '@/components/DailyPayments/DailyPaymentsList';
  export default {
    components:{
      DailyPaymentsList
    }
    
  }
</script>