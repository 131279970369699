<template>
  <div>
    <v-divider class="mt-4"></v-divider>

    <v-card-text id="table-actions" class="d-flex align-baseline flex-wrap pb-0">
      <div class="text-center mr-3">
        <v-menu
        v-model="dateMenuSince"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateFormattedSince"
            label="Desde"
            readonly
            dense
            clearable
            v-bind="attrs"
            v-on="on"
          ></v-text-field>

        </template>
        <v-date-picker
          v-model="date_since"
          :first-day-of-week="0"
          :max="date_to"
          locale="es-es"
          color="primary"
          @input="dateMenuSince = false"
          @change="getDailyPayments"
        ></v-date-picker>
      </v-menu>
      </div>

      <v-spacer></v-spacer>

      <div class="text-center mr-3">
        <v-menu
        v-model="dateMenuTo"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateFormattedTo"
            label="Hasta"
            readonly
            dense
            clearable
            v-bind="attrs"
            v-on="on"
          ></v-text-field>

        </template>
        <v-date-picker
          v-model="date_to"
          :first-day-of-week="0"
          :max="current_date"
          :min="date_since"
          locale="es-es"
          color="primary"
          @input="dateMenuTo = false"
          @change="getDailyPayments"
        ></v-date-picker>
      </v-menu>
      </div>

      <v-spacer></v-spacer>

      <v-select
        :items="paymentMethodsList"
        hide-details="true"
        style="width: 15%"
        v-model="paymentMethod"
        label="Método de pago"
        item-text="name"
        item-value="id"
        @change="getDailyPayments"
      ></v-select>
      <v-spacer></v-spacer>

      <div class="d-flex align-center flex-wrap">
        <v-btn 
          color="primary"
          class="mb-4 ml-2"
          @click="() => printView()"
        >
        <v-icon class="me-2">{{ icons.mdiFilePdfBox }}</v-icon>
        <span>Imprimir</span>
        </v-btn>
      </div>
    </v-card-text>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="dailyPayments"
      :page="current_page"
      :server-items-length="totalListTable"
      :footer-props="{
        'items-per-page-options': [10, 20, 50],
        'items-per-page-text': 'Items por página',
        'page-text': '{0}-{1} de {2}'
      }"
      @update:page="changingDailyPaymentsPage($event)"
      @update:items-per-page="changingItemsPerPage($event)"
    > 
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          No se encontraron
          <strong>pagos</strong>
        </v-list-item-title>
      </v-list-item>
    </template>

    <template v-slot:top>
      <v-divider></v-divider>
      <v-toolbar flat>
      <span class="print-dates" v-show="false">
        <span> <b>Desde:</b> {{ dateFormattedSince }}</span>
        <span class="ml-6"> <b>Hasta:</b> {{ dateFormattedTo }}</span>
      </span>
        <v-spacer></v-spacer>
        <v-chip class="primary--text">
          Total pagado: ${{ total_paid ? total_paid : 0  }}
        </v-chip>
      </v-toolbar>
    </template>

    <template #[`item.sku`]="{item}">
      <span class="font-weight-bold">{{ item.sku }}</span>
    </template>

    <template #[`item.payment_method`]="{item}">
      <span class="font-weight-medium">{{ item.payment_method.label }}</span>
    </template>

    <template #[`item.price`]="{item}">
      <span class="font-weight-medium">${{ item.price }}</span>
    </template>

    <template #[`item.reference`]="{item}">
      <span 
      style="max-width: 200px;" 
      class="d-inline-block text-truncate reference-span"
      @click="showFullReference(item.reference)"
      >
        {{ item.reference }}
      </span>
    </template>
    </v-data-table>


    <template>
      <v-dialog v-model="showingFullReferenceModal" max-width="500">
        <v-card class="pa-3">
          <v-card-title class="d-flex justify-center">
              Observación completa
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
              {{ fullReference }}
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="showingFullReferenceModal = false">
            Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import {
  mdiFilePdfBox
} from '@mdi/js'
export default {
  data: () => {
    return {
      paymentMethod: -1,
      paymentMethodsList: [{id: -1, name: 'Todos los medios'}, {id: 0, name: 'Efectivo'}, {id: 1, name: 'Transferencia'}, {id: 2, name: 'Tarjeta de crédito'}, {id: 3, name: 'Tarjeta de débito'}, {id: 4, name: 'Mercado Pago'}],
      current_page: 1,
      page_size: 10,
      dateMenuSince: false,
      dateMenuTo: false,
      current_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date_since: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date_to: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      loading: false,
      showingFullReferenceModal: false,
      fullReference: '',
      total_paid: null
    }
  },
  mounted() {
    this.getDailyPayments()
  },
  methods: {
    async getDailyPayments() {
      this.loading = true
      try {
        let form = {
          page: this.current_page,
          page_size: this.page_size,
          payment_method: this.paymentMethod,
          date_since: this.date_since,
          date_to: this.date_to
        }

        const response = await this.$api.getDailyPayments(form)
        this.dailyPayments = response.registros ? response.registros : []
        this.totalListTable = response.registros ? response.registros.length : 0
        this.total_paid = response.total_pagado ? response.total_pagado : null

      } catch(e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    changingDailyPaymentsPage(e) {
      this.current_page = e
      this.getDailyPayments()
    },
    changingItemsPerPage(e) {
      this.page_size = e
      this.getDailyPayments()
    },
    formatShowingDate(dates) {
      if (!dates) return null
      const [year, month, day] = dates.split('-')

      return `${day}/${month}/${year}`
    },
    formatDateToSend(dates) {
      if (!dates) return null;
      const [day, month, year] = dates.split('/');
      
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    printView() {
      window.print()
    },
    showFullReference(reference) {
      this.fullReference = reference
      this.showingFullReferenceModal = true
    }
  },
  computed: {
    dateFormattedSince: {
      get() { return this.formatShowingDate(this.date_since);},
      set(value) { this.date_since = this.formatDateToSend(value);}
    },
    dateFormattedTo: {
      get() { return this.formatShowingDate(this.date_to);},
      set(value) { this.date_to = this.formatDateToSend(value);}
    },
    userData() { return this.$store.getters['session/userData']() },
  },
  setup() {
    const dailyPayments = []
    const totalListTable = 0
    const headers = [
        { text: 'SKU', align: 'center', sortable: false, value: 'sku'},
        { text: 'METODO DE PAGO', align: 'center', sortable: false, value: 'payment_method'},
        { text: 'MONTO', align: 'center',sortable: false, value: 'price'},
        { text: 'OBSERVACIONES', align: 'center', sortable: false, value: 'reference'},
    ]
    return {
      dailyPayments,
      totalListTable,
      headers,
      icons: {
        mdiFilePdfBox
      }
    }
  }
}
</script>

<style>
.reference-span {
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
}

.reference-span:hover {
  background-color: rgba(185, 185, 185, 0.3);
}
@media print {
  .v-application {
    background: none !important;
  }
  
  /* hide page navs & footers */
  .app-navigation-menu,
  .v-app-bar,
  .v-footer {
    display: none !important;
  }
  
  /* hidding elements from this component */
  #table-actions{
    display: none !important;
  }

  .print-dates {
    display: inline-block !important;
  }

  .v-data-footer{
    display: none !important;
  }

  /* set no paddings.. can u adjust on print interface */
  .v-main,
  .app-content-container {
    padding: 0px !important;
  }
  
  /* // make flat cards for print */
  .v-card {
    padding-top:20px !important;
    box-shadow: none !important;
  }
}
</style>