import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDivider,{staticClass:"mt-4"}),_c(VCardText,{staticClass:"d-flex align-baseline flex-wrap pb-0",attrs:{"id":"table-actions"}},[_c('div',{staticClass:"text-center mr-3"},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Desde","readonly":"","dense":"","clearable":""},model:{value:(_vm.dateFormattedSince),callback:function ($$v) {_vm.dateFormattedSince=$$v},expression:"dateFormattedSince"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenuSince),callback:function ($$v) {_vm.dateMenuSince=$$v},expression:"dateMenuSince"}},[_c(VDatePicker,{attrs:{"first-day-of-week":0,"max":_vm.date_to,"locale":"es-es","color":"primary"},on:{"input":function($event){_vm.dateMenuSince = false},"change":_vm.getDailyPayments},model:{value:(_vm.date_since),callback:function ($$v) {_vm.date_since=$$v},expression:"date_since"}})],1)],1),_c(VSpacer),_c('div',{staticClass:"text-center mr-3"},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Hasta","readonly":"","dense":"","clearable":""},model:{value:(_vm.dateFormattedTo),callback:function ($$v) {_vm.dateFormattedTo=$$v},expression:"dateFormattedTo"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenuTo),callback:function ($$v) {_vm.dateMenuTo=$$v},expression:"dateMenuTo"}},[_c(VDatePicker,{attrs:{"first-day-of-week":0,"max":_vm.current_date,"min":_vm.date_since,"locale":"es-es","color":"primary"},on:{"input":function($event){_vm.dateMenuTo = false},"change":_vm.getDailyPayments},model:{value:(_vm.date_to),callback:function ($$v) {_vm.date_to=$$v},expression:"date_to"}})],1)],1),_c(VSpacer),_c(VSelect,{staticStyle:{"width":"15%"},attrs:{"items":_vm.paymentMethodsList,"hide-details":"true","label":"Método de pago","item-text":"name","item-value":"id"},on:{"change":_vm.getDailyPayments},model:{value:(_vm.paymentMethod),callback:function ($$v) {_vm.paymentMethod=$$v},expression:"paymentMethod"}}),_c(VSpacer),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c(VBtn,{staticClass:"mb-4 ml-2",attrs:{"color":"primary"},on:{"click":function () { return _vm.printView(); }}},[_c(VIcon,{staticClass:"me-2"},[_vm._v(_vm._s(_vm.icons.mdiFilePdfBox))]),_c('span',[_vm._v("Imprimir")])],1)],1)],1),_c(VDataTable,{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.dailyPayments,"page":_vm.current_page,"server-items-length":_vm.totalListTable,"footer-props":{
      'items-per-page-options': [10, 20, 50],
      'items-per-page-text': 'Items por página',
      'page-text': '{0}-{1} de {2}'
    }},on:{"update:page":function($event){return _vm.changingDailyPaymentsPage($event)},"update:items-per-page":function($event){return _vm.changingItemsPerPage($event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c(VListItem,[_c(VListItemTitle,[_vm._v(" No se encontraron "),_c('strong',[_vm._v("pagos")])])],1)]},proxy:true},{key:"top",fn:function(){return [_c(VDivider),_c(VToolbar,{attrs:{"flat":""}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"print-dates"},[_c('span',[_c('b',[_vm._v("Desde:")]),_vm._v(" "+_vm._s(_vm.dateFormattedSince))]),_c('span',{staticClass:"ml-6"},[_c('b',[_vm._v("Hasta:")]),_vm._v(" "+_vm._s(_vm.dateFormattedTo))])]),_c(VSpacer),_c(VChip,{staticClass:"primary--text"},[_vm._v(" Total pagado: $"+_vm._s(_vm.total_paid ? _vm.total_paid : 0)+" ")])],1)]},proxy:true},{key:"item.sku",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.sku))])]}},{key:"item.payment_method",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.payment_method.label))])]}},{key:"item.price",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium"},[_vm._v("$"+_vm._s(item.price))])]}},{key:"item.reference",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate reference-span",staticStyle:{"max-width":"200px"},on:{"click":function($event){return _vm.showFullReference(item.reference)}}},[_vm._v(" "+_vm._s(item.reference)+" ")])]}}],null,true)}),[_c(VDialog,{attrs:{"max-width":"500"},model:{value:(_vm.showingFullReferenceModal),callback:function ($$v) {_vm.showingFullReferenceModal=$$v},expression:"showingFullReferenceModal"}},[_c(VCard,{staticClass:"pa-3"},[_c(VCardTitle,{staticClass:"d-flex justify-center"},[_vm._v(" Observación completa ")]),_c(VDivider),_c(VCardText,[_vm._v(" "+_vm._s(_vm.fullReference)+" ")]),_c(VDivider),_c(VCardActions,{staticClass:"pb-0"},[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.showingFullReferenceModal = false}}},[_vm._v(" Cerrar ")])],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }